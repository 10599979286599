.row-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: center; */
}

.row-transcribe-translate {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 60px - 50px);
    /* justify-content: space-between; */
    /* align-items: center; */
    width: 100%;
}

.column-transcribe {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 10px;
    transition: width 1s;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* width: 100%; */
}

.column-translate {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 10px;
    transition: width 1s;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* width: 100%; */
}

.transcribe-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    /* height: calc(100vh - 60px - 50px); */
    padding-top: 30px;
    padding-left: 8%;
    padding-right: 8%;
    width: 100%;
    /* padding-bottom: 100px; */
}

.transcription-box {
    font-size: 14px;
    width: 100%;
    height: 50%;
    /* padding: 10px 15px; */
    /* padding: 0 10px; */
    padding-right: 15px;
    padding-left: 10px;
    border: 1px solid var(--form-border-color);
    border-radius: 10px;
    background-color: white;
    color: black;
    font-family: Helvetica;
}

.transcription-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-size: 14px;
    width: 100%;
    height: 50%;
    padding: 15px 10px;
    /* padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px; */
    border: 1px solid var(--form-border-color);
    border-radius: 10px;
    background-color: white;
    color: black;
    font-family: Helvetica;
}

.transcription-options {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    /* width: 100%;
    height: 50px; */
    /* margin-top: 20px; */
}

.transcription-text {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
    font-size: 16px;
    padding-right: 10px;
    /* margin-right: 30px; */
    width: 100%;
    height: 100%;
    outline: none;
    border: 0px;
    border-radius: 10px;
    background-color: white;
    color: black;
    font-family: Helvetica;
    resize: none;
}

.transcription-text::-webkit-scrollbar {
    width: 8px;
}

.transcription-text::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.transcription-text::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 15px;
}

.transcription-text::-webkit-scrollbar-thumb:hover {
    background: #929292;
}


.full-height {
    height: 100%;
}

.control-bar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding: 0 10px; */
    padding-right: 15px;
    padding-left: 10px;
    width: 100%;
    height: 70px;
    border: 1px solid var(--form-border-color);
    border-radius: 10px;
    background-color: white;
    color: black;
}

.recording-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    /* margin-top: 20px; */
}

.rec-controls-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* height: 50px; */
}

.rec-controls-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.recording-text {
    font-size: 12px;
    padding: 0 10px;
    font-family: Helvetica;
}

.circle-container {
    /* width: 100px;
    height: 100px; */
    position: relative;
    margin: 0 10px;
    background-color: black;
    color: white;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-size: 20px;
    border: 1px solid black;
    font-family: Helvetica;
    font-size: 14px;
    cursor: pointer;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes colorChange {
    0% {
        background-color: rgb(200, 200, 200);
    }

    50% {
        background-color: rgb(255, 255, 255);
    }

    100% {
        background-color: rgb(200, 200, 200);
    }
}

@keyframes drawCircle {
    0% {
        border-width: 0;
    }

    100% {
        border-width: 3px;
    }
}

@keyframes borderColorChange {
    0% {
        border-color: rgb(240, 240, 240);
    }

    50% {
        border-color: white;
    }

    100% {
        border-color: rgb(240, 240, 240);
    }
}

.mic-icon:hover {
    /* animation: pulse 1.3s infinite; */
    /* animation: rotate 2s linear infinite; */
    /* animation: slideIn 2s; */
    /* animation: bounce 2s infinite; */
    animation: colorChange 2s infinite;
    /* animation: drawCircle 2s forwards */
    /* animation: borderColorChange 3s infinite; */
}

.mic-icon {
    border-radius: 50%;
    padding: 4px;
    border: 1px solid transparent;
    /* box-sizing: border-box; */
    /* border-color: red; */
}

.icon-button {
    cursor: pointer;
    padding: 0 5px;
    position: relative;
}

@keyframes throbbing {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.throbbing {
    animation: throbbing 1s infinite;
    filter: brightness(0) saturate(100%) invert(34%) sepia(97%) saturate(6703%) hue-rotate(360deg) brightness(102%) contrast(104%);
}

.link-button {
    cursor: pointer;
    padding: 0 7px;
    margin: 0 5px;
    font-size: 14px;
    border-width: 1px;
    border-style: solid;
}

.language-dropdown {
    /* margin-left: 5px;
    margin-right: 10px; */
    /* appearance: none; */
    font-size: 14px;
    border-width: 1px;
    border-radius: 20px;
    height: 34px;
    padding: 0px 12px;
    margin: 0px 5px;
    background-color: black;
    color: white;
    cursor: pointer;
}

.option-item {
    padding: 20px 0px;
    color: black;
    background-color: white;
    margin: 10px 0px;

}

.loading {
    transform: scale(0.5);
}

.deleting {
    transform: scale(0.2);
}

.slider {
    /* -webkit-appearance: none; */
    /* appearance: none; */
    display: flex;
    flex: row;
    width: 300px;
    height: 34px;
    border-radius: 20px;
    background: white;
    border: 1px solid var(--form-border-color)
        /* outline: none; */
        /* opacity: 0.7; */
        /* -webkit-transition: .2s;
    transition: opacity .2s; */
}

.slider-thumb {
    /* -webkit-appearance: none; */
    /* appearance: none; */
    display: flex;
    flex: column;
    justify-content: center;
    align-items: center;
    width: 146px;
    height: 30px;
    margin: 2px;
    border-radius: 20px;
    cursor: pointer;
    font-family: Helvetica;
    font-size: 14px;
}

.thumb-on {
    background: black;
    color: white;
}

.thumb-off {
    background: white;
    color: black;
}

.confirm-modal {
    position: fixed;
    top: 26%;
    left: 50%;
    transform: translate(-50%, 0%);
    /* min-width: 200px;  */
    /* height: 200px; */
    /* background: white; */
    text-align: center;
    padding: 20px;
    border: 1px solid rgb(70, 70, 70);
    border-radius: 10px;
    outline: none;
    background-color: rgb(230, 229, 229);
}

.list-modal {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 400px;
    height: 400px;
    /* background: white; */
    text-align: center;
    padding: 15px;
    border: 1px solid rgb(70, 70, 70);
    border-radius: 10px;
    outline: none;
    background-color: rgb(230, 229, 229);
    overflow: auto;
}

.overlay {
    /* background-color: rgba(255, 255, 255, 1); */
    background-color: blue !important;
    /* white with 75% opacity */
}

/* .flash-modal {
    position: fixed;
    top: 30%;
    left: 62%;
    transform: translate(-50%, 0%);
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    background-color: gray;
    font-size: 13px;
} */

.list-item:hover {
    text-decoration: underline;
}

.list-item {
    font-size: 16px;
    cursor: pointer;
    margin: 5px 0;
}

.list-items:hover {
    text-decoration: underline;
}

.list-items {
    font-family: Helvetica;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px 15px;
    /* padding: 10px;  */
    cursor: pointer;
}

.modal-header {
    font-family: Helvetica;
    font-size: 20px;
    font-weight: semi-bold;
    margin-top: 10px;
    margin-bottom: 20px;
}

.modal-confirm-text {
    margin: 20px 10px;
    font-size: 18px;
    font-weight: normal;
    font-family: Helvetica;
    font-size: 18px;
}

.modal-error-text {
    /* margin: 20px 10px; */
    font-size: 12px;
    color: red;
}

.modal-action-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 20px; */
}

.modal-action-link {
    cursor: pointer;
    margin: 10px;
    padding: 10px 20px;
    font-family: Helvetica;
    font-size: 14px;
    border-radius: 20px;
    background-color: black;
    color: white;
}

.modal-action-link-reverse {
    cursor: pointer;
    margin: 10px;
    padding: 10px 20px;
    font-family: Helvetica;
    font-size: 14px;
    border: 1px solid black;
    border-radius: 20px;
    background-color: white;
    color: black;
}

.wp-input-field {
    /* margin: 10px 0; */
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 360px;
    /* height: 34px; */
    border: 1px solid var(--form-border-color);
    /* border-color: #000; */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 1px;
    color: #000;
}

.wp-textarea {
    margin-top: 10px;
    height: 300px;
    width: 360px;
    padding: 10px;
    border: 1px solid var(--form-border-color);
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 16px;
    line-height: 1.5;
    height: calc(8 * 16px * 1.5);
    /* width: 300px; */
}

.wp-email-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* min-width: 200px;  */
    /* height: 200px; */
    /* background: white; */
    text-align: center;
    padding: 20px;
    border: 1px solid rgb(70, 70, 70);
    border-radius: 10px;
    outline: none;
    background-color: rgb(230, 229, 229);
}