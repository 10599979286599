:root {
  --form-border-color: #818181;
  --focus-border-color: #E89400;
}

.App {
  text-align: center;
  height: 100vh;
  min-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* width: 100%; */
}


.app-column {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  text-align: center;
  height: 100vh;
  min-width: 600px;
  width: 100%;
}

.panel-icon {
  cursor: pointer;
}

.panel {
  /* position: fixed;
  top: 0;
  left: 0; */
  padding: 20px;
  /* padding-left: 20px;
  padding-top: 20px; */
  height: 100%;
  width: 30px;
  min-width: 30px;
  overflow-y: auto;
  transition: width 0.5s;
  font-family: Helvetica;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  /* justify-content: left; */
  align-items: flex-start;
  background-color: rgb(240, 240, 240);
}

.panel.open {
  width: 250px;
  min-width: 100px;
  /* Or however wide you want the panel to be */
}

.transcription-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: flex-start; */
}

.transcription-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* text-align: left;
  align-items: flex-start;
  justify-content: flex-start; */
  margin: 9px 0;
  cursor: pointer;
}

.trash-icon {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.transcription-entry:hover .trash-icon {
  opacity: 1;
}

.logo-small {
  height: 40px;
  margin: 10px;
}

.header-container {
  box-sizing: border-box;
  height: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 10px; */
  padding-left: 8%;
  padding-right: 8%;
}

.header-container-default {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-left {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: bold;
  /* padding-left: 30px; */
  /* justify-content: space-evenly; */
}


.header-right {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.language-button {
  margin: 10px;
}

.button-small {
  /* margin: 10px 0; */
  border-radius: 20px;
  background-color: black;
  color: white;
  font-family: Helvetica;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  /* height: 38px; */
  /* width: 90px; */
}

.button-small-reverse {
  /* margin: 10px 0; */
  border-radius: 20px;
  border: 1px solid var(--form-border-color);
  background-color: white;
  padding: 10px 15px;
  color: black;
  font-family: Helvetica;
  font-size: 14px;
  /* padding: 8px 10px; */
  cursor: pointer;
  /* height: 38px;
  width: 90px; */
}

.button-small-nowidth {
  /* margin: 10px 0; */
  border-radius: 20px;
  background-color: black;
  color: white;
  font-family: Helvetica;
  font-size: 12px;
  /* padding: 8px 10px; */
  cursor: pointer;
  height: 35px;
  /* width: 80px; */
}


.header-link {
  height: 30px;
}


.app-title {
  font-size: 45px;
  font-weight: bold;
  font-family: Helvetica;
  letter-spacing: 1px;
}

.app-body-large-text {
  font-size: 36px;
  font-family: Helvetica;
  font-weight: semi-bold;
}

.app-body {
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* width: 100vh; */
}

.app-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20%;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: beige;
}

.row-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  /* width: 100%; */
}

.column-container {
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  align-self: flex-start;
  /* width: 100%; */
}

.menu-highlight {
  font-size: 19px;
  font-weight: bold;
}

.menu-item {
  font-size: 18px;
  cursor: pointer;
  margin: 10px 0;
}

.normal-text {
  color: black;
  font-family: Helvetica;
  font-size: 16px;
}

.status-text {
  color: black;
  font-size: 12px;
}

.link-text {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link-color {
  color: #E89400;
  /* text-decoration: underline; */
  cursor: pointer;
}

.link-text2 {
  color: #E89400;
  font-family: Helvetica;
  font-size: 16px;
  /* text-decoration: underline; */
  cursor: pointer;
}

.small-font {
  font-size: 12px;
  font-family: Helvetica;
}

.error-text {
  /* text-align: left; */
  margin: 5px 10px;
  color: rgb(235, 61, 61);
  font-size: 14px;
  font-family: Helvetica;
}

.error-text-small {
  text-align: left;
  margin: 5px 10px;
  color: rgb(235, 61, 61);
  font-size: 12px;
  font-family: Helvetica;
  width: 300px;
}

.v-spacer {
  height: 20px;
}

.v-spacer-small {
  height: 5px;
}

.v-spacer-tiny {
  height: 3px;
}

.h-spacer {
  width: 10px;
}

.h-spacer-small {
  width: 5px;
}

.h-spacer-tiny {
  width: 3px;
}

.h-spacer-wide {
  width: 40px;
}

.flash-modal {
  position: fixed;
  top: 30%;
  left: 62%;
  transform: translate(-50%, 0%);
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  background-color: gray;
  font-size: 13px;
}

.flash-copy-absolute {
  position: absolute;
  top: -44px;
  left: -64px;
  /* transform: translate(-50%, 0%); */
  text-align: center;
  padding: 13px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 20px;
  outline: none;
  background-color: black;
  color: white;
  font-size: 13px;
}

.flash-mic-absolute {
  position: absolute;
  top: -20px;
  left: 30px;
  /* transform: translate(-50%, 0%); */
  text-align: left;
  padding: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0px;
  outline: none;
  background-color: rgb(200, 200, 200);
  color: white;
  font-size: 12px;
  /* animation: pulse2 2s 5; */
  animation: colorChange2 2s 5;
  /* animation: rotate 2s linear infinite; */
}

.flash-go-absolute {
  position: absolute;
  top: -23px;
  left: 35px;
  /* transform: translate(-50%, 0%); */
  text-align: left;
  padding: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0px;
  outline: none;
  background-color: rgb(200, 200, 200);
  color: white;
  font-size: 12px;
  /* animation: pulse2 2s 5; */
  animation: colorChange2 2s 5;
  /* animation: rotate 2s linear infinite; */
}

.flash-inputmsg-absolute {
  position: absolute;
  top: -23px;
  left: 210px;
  /* transform: translate(-50%, 0%); */
  text-align: left;
  padding: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0px;
  outline: none;
  background-color: rgb(200, 200, 200);
  color: white;
  font-size: 12px;
  /* animation: pulse2 2s 5; */
  animation: colorChange2 2s 5;
  /* animation: rotate 2s linear infinite; */
}

.flash-savedtext-absolute {
  position: absolute;
  top: 10px;
  left: 45px;
  /* transform: translate(-50%, 0%); */
  text-align: left;
  padding: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0px;
  outline: none;
  background-color: rgb(200, 200, 200);
  color: white;
  font-size: 12px;
  /* animation: pulse2 2s 5; */
  animation: colorChange2 2s 5;
  /* animation: rotate 2s linear infinite; */
}

@keyframes colorChange2 {
  0% {
    background-color: rgb(134, 134, 134);
  }

  50% {
    background-color: rgb(200, 200, 200);
  }

  100% {
    background-color: rgb(134, 134, 134);
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.flash-mic-text {
  white-space: nowrap;
}

.flash-message-sent {
  position: absolute;
  top: 5%;
  left: 50%;
  /* position: fixed;
  top: 20%;
  left: 50%; */
  transform: translate(-50%, 0%);
  text-align: center;
  padding: 15px 25px;
  border-radius: 20px;
  outline: none;
  background-color: rgb(92, 92, 92);
  color: white;
  font-size: 16px;
}


.modal-title {
  font-size: 24px;
  font-weight: normal;
  font-family: Helvetica;
  /* margin: 10px 0; */
}


/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */