.login-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - 50px);
    margin-top: 10%;
}

.logo {
    width: 100px;
    /* Adjust as needed */
    margin-bottom: 20px;
}

.input-field {
    /* margin: 10px 0; */
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 300px;
    /* height: 34px; */
    border: 1px solid var(--form-border-color);
    /* border-color: var(--form-border-color); */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 1px;
    color: #000;
}

.input-field:focus {
    border-color: var(--focus-border-color);
    border-width: 1px;
    outline: none;
}

.input-field-medium {
    /* margin: 10px 0; */
    margin-top: 10px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 300px;
    /* height: 34px; */
    border: 1px solid var(--form-border-color);
    /* border-color: var(--form-border-color); */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 15px;
    letter-spacing: 1px;
    color: #000;
}

.input-field-medium:focus {
    border-color: var(--focus-border-color);
    border-width: 1px;
    outline: none;
}

.submit-button-style-medium {
    /* margin: 10px 0; */
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 300px;
    border: 1px solid var(--form-border-color);
    /* border-color: var(--form-border-color); */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 15px;
    letter-spacing: 1px;
    color: white;
    background-color: black;
    cursor: pointer;
}

.submit-button-medium-loading {
    /* margin: 10px 0; */
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 268px;
    border: 1px solid var(--form-border-color);
    /* border-color: var(--form-border-color); */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 15px;
    letter-spacing: 1px;
    color: white;
    background-color: black;
    cursor: pointer;
}

.dropdown-field-medium {
    /* margin: 10px 0; */
    margin-top: 7px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 336px;
    /* height: 34px; */
    border: 1px solid var(--form-border-color);
    /* border-color: var(--form-border-color); */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 15px;
    letter-spacing: 1px;
    color: #000;
}

.dropdown-field-medium:focus {
    border-color: green;
    border-width: 1px;
    outline: none;
}

.drop-option {
    /* margin: 10px 0; */
    font-family: Helvetica;
    font-size: 15px;
}

.login-container form input {
    display: block;
    /* margin-bottom: 10px; */
    /* Adjust as needed */
}

.submit-style {
    display: flex;
    justify-content: center;
}

.submit-button-style {
    /* margin: 10px 0; */
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 338px;
    border: 1px solid var(--form-border-color);
    /* border-color: var(--form-border-color); */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 1px;
    color: white;
    background-color: black;
    cursor: pointer;
}


.submit-button-loading {
    /* margin: 10px 0; */
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 306px;
    border: 1px solid var(--form-border-color);
    /* border-color: var(--form-border-color); */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 16px;
    letter-spacing: 1px;
    color: white;
    background-color: black;
    cursor: pointer;
}

.flash-modal-login {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    outline: none;
    background-color: black;
    font-size: 16px;
    color: white;
}

.modal-signin-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-signin-message-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 30px 40px;
    border-radius: 5px;
    width: 350px;
    max-width: 500px;
    border: 1px solid var(--form-border-color);
    border-radius: 15px;
    font-family: Helvetica;
    font-size: 18px;
    /* font-family: Helvetica;
    font-size: 16px; */
}

.modal-password-reset-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 30px 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 500px;
    border: 1px solid var(--form-border-color);
    border-radius: 15px;
    font-family: Helvetica;
    font-size: 20px;
}

.modal-signup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-signup-content {
    background-color: white;
    padding: 20px 50px;
    border-radius: 15px;
    border: 1px solid var(--form-border-color);
    /* width: 300px; */
    max-width: 700px;
}

.modal-signup-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-field {
    /* margin: 10px 0; */
    margin-top: 10px;
    height: 30px;
    width: 100%;
    padding: 5px;
    /* width: 300px; */
}

.textarea-field {
    margin-top: 10px;
    height: 60px;
    width: 310px;
    padding: 10px;
    border: 1px solid var(--form-border-color);
    /* border-color: var(--form-border-color); */
    border-radius: 10px;
    font-family: Helvetica;
    font-size: 15px;
    /* width: 300px; */
}

.textarea-field:focus {
    border-color: var(--focus-border-color);
    border-width: 1px;
    outline: none;
}

.button-group {
    display: flex;
    justify-content: space-between;
    width: 300px;
}